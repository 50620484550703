<script>
import axios from 'axios';
import {
  showError,
  showLoading,
  showMessage,
  handleErrorResponse, closeLoading,
} from '@/components/widgets/swalUtils';
import { required, helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

import appConfig from '@/app.config.json';

/**
 * Recover password Sample page
 */
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  validations: {
    password: {
      required: helpers.withMessage('Password is required', required),
    },
    password_confirmation: {
      required: helpers.withMessage('Confirm password is required', required),
    },
  },
  data() {
    return {
      submitted: false,
      password: '',
      password_confirmation: '',
    };
  },
  methods: {
    tryToResetpwd() {
      this.submitted = true;
      // stop here if form is invalid
      this.v$.$touch();

      if (this.v$.$invalid) {
        showError('We found errors in the form.');
      } else {
        showLoading('Processing...');

        axios.post('/auth/forgot-password/reset', {
          token: this.$route.params.token,
          password: this.password,
          password_confirmation: this.password_confirmation,
        }).then((result) => {
          if (result.data?.error) {
            handleErrorResponse(result.data.error);
            return this.$router.push('/forgot-password');
          }
          showMessage({
            title: 'Password updated!',
            text: result.data.success,
          });
          return this.$router.push('/login');
        });
      }
    },
  },
  async mounted() {
    if (this.$route.params.token) {
      // Try to validate the token
      await showLoading('Processing...');

      await axios.get(`/auth/forgot-password/${this.$route.params.token}`).then((response) => {
        if (response.data?.error) {
          showError('The token associated with your account has expired.');
          return this.$router.push('/forgot-password');
        }
        return closeLoading();
      });
    } else {
      await showError('The token associated with your account has not been found.');
      return this.$router.push('/forgot-password');
    }
    return [];
  },
  page: {
    title: 'Reset password',
    meta: [
      {
        name: 'description',
        content: appConfig.description,
      },
    ],
  },
};
</script>

<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card overflow-hidden">
            <div class="bg-soft-primary">
              <div class="row">
                <div class="col-7">
                  <div class="text-primary p-4">
                    <h5 class="text-primary">Reset Password</h5>
                    <p>Reset Password for <br>Command & Planning 4.</p>
                  </div>
                </div>
                <div class="col-5 align-self-end pb-3">
                  <img
                    alt="Logo"
                    class="img-fluid"
                    src="@/assets/images/logo.png"
                  />
                </div>
              </div>
            </div>
            <div class="card-body pt-0">
              <div>
                <a href="/">
                  <div class="avatar-md profile-user-wid mb-4">
                    <span class="avatar-title rounded-circle bg-light">
                      <img
                        alt="Logo"
                        class="rounded-circle"
                        height="34"
                        src="@/assets/images/logo.svg"
                      />
                    </span>
                  </div>
                </a>
              </div>

              <div class="p-2">
                <form class="form-horizontal" @submit.prevent="tryToResetpwd">
                  <div class="form-group">
                    <label for="pwd">Password
                      <input
                        id="pwd"
                        v-model="password"
                        :class="{
                          'is-invalid': submitted && $v.password.$error,
                        }"
                        class="form-control"
                        placeholder="Enter password"
                        type="password"
                      />
                    </label>
                    <div
                      v-if="submitted && v$.user.password.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.user.password.required.$message">{{
                          v$.user.password.required.$message
                        }}</span>
                    </div>
                  </div>

                  <div class="form-group mt-4">
                    <label for="confirm_pwd">Confirm Password
                      <input
                        id="confirm_pwd"
                        v-model="password_confirmation"
                        :class="{
                        'is-invalid':
                          submitted && $v.password_confirmation.$error,
                      }"
                        class="form-control"
                        placeholder="Enter confirm password"
                        type="password"
                      />
                    </label>
                    <div
                      v-if="submitted && v$.password_confirmation.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="v$.password_confirmation.required.$message">{{
                          v$.password_confirmation.required.$message
                        }}</span>
                    </div>
                  </div>
                  <div class="form-group row mb-0 mt-4">
                    <div class="col-12 text-end">
                      <button class="btn btn-primary w-md" type="submit">
                        Reset
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="mt-5 text-center">
            <p>
              Remember It ?
              <router-link class="fw-medium text-primary" to="/login"
              >Sign In here
              </router-link
              >
            </p>
            <p>
              © {{ new Date().getFullYear() }} Omnigo Software
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
